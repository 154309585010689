export enum MealType {
  Breakfast = "Breakfast",
  Lunch = "Lunch",
  Dinner = "Dinner",
  Snack = "Snack",
  Unspecified = "Unspecified",
}

export type NutritionData = {
  id: string
  calories: number
  proteins: number
  fats: number
  carbohydrates: number
}

export type Ingredient = {
  ingredient: string
  quantity: number
  unit: string
}

export type FoodItem = {
  id: string
  food: string
  source: string
  timeframe: string
  text: string
  meal: MealType
  mealId: string
  unit: string
  size: string
  quantity: number
  modifiers: string[]
  ingredients: Ingredient[]
  createdAt: Date
  updatedAt: Date
  consumedAt: Date | null
  confirmedAt: Date | null
  messageId: string | null
  threadId: string | null
  nutritionData: NutritionData | null
}

export type Meal = {
  id: string
  displayName: string
  createdAt: Date
  updatedAt: Date
  consumedAt: Date
  timeframe: string
  items: FoodItem[]
}

export type GetMealsResponse = {
  meals: Meal[]
}

export type DeleteEntityFeedback = {
  deleteReason: string | null
  deleteComment: string | null
}
