export type Asset = ReturnType<typeof require>

export const Icons: Record<string, Asset> = {
  AppleHealth: require("./icons/AppleHealth.png"),
} as const

export const Images: Record<string, Asset> = {
  FTUE1: require("./images/FTUE-1.png"),
  FTUE2: require("./images/FTUE-2.png"),
  FTUE3: require("./images/FTUE-3.png"),
  FTUE4: require("./images/FTUE-4.png"),
  HomeBackgroundWeb: require("./images/HomeBackground-web.png"),
  HomeBackgroundNative: require("./images/HomeBackground-native.png"),
} as const
