import { Environment } from "@my/config/src/environment"
import { createContext } from "react"
import { useAuth } from "../auth/useAuth"
import { reviveWithDates } from "./reviveWithDates"

/**
 * Options that can be specified for each http request.
 */
export type HttpRequestOptions = {
  /**
   * The API version to use for the request (default: 1).
   */
  apiVersion?: number
  /**
   * Query parameters to include in the request.
   */
  queryParams?: Record<string, string>
  /**
   * Optional error handlers for the request. If a handler is not specified, or if the handler
   * returns false (meaning not handled), the default error handling will be used.
   */
  errorHandlers?: ErrorHandlers
}

type ErrorHandlers = {
  onBadRequest?: () => boolean
  onUnauthorized?: () => boolean
  onForbidden?: () => boolean
  onGeoblocked?: () => boolean
  onNotFound?: () => boolean
  onServerError?: () => boolean
}

type IHttpClientContext = {
  readonly isInitialized: boolean
  readonly get: <ResponseType>(
    path: string,
    options?: HttpRequestOptions,
  ) => Promise<ResponseType | null>
  readonly post: <ResponseType>(
    path: string,
    body?: any,
    options?: HttpRequestOptions,
  ) => Promise<ResponseType | null>
  readonly put: <ResponseType>(
    path: string,
    body?: any,
    options?: HttpRequestOptions,
  ) => Promise<ResponseType | null>
  readonly patch: <ResponseType>(
    path: string,
    body?: any,
    options?: HttpRequestOptions,
  ) => Promise<ResponseType | null>
  readonly delete: (
    path: string,
    body?: any,
    options?: HttpRequestOptions,
  ) => Promise<boolean | null>

  /**
   * Gets the HTTP status code from the last API response.
   * @returns The numeric HTTP status code or undefined if no request has been made
   */
  get lastResponseStatus(): number | undefined
}

const defaultHttpClientContext = {
  isInitialized: false,
  get: () => {
    throw Error("not implemented!")
  },
  post: () => {
    throw Error("not implemented!")
  },
  put: () => {
    throw Error("not implemented!")
  },
  patch: () => {
    throw Error("not implemented!")
  },
  delete: () => {
    throw Error("not implemented!")
  },
  lastResponseStatus: undefined,
} satisfies IHttpClientContext

export const HttpClientContext = createContext<IHttpClientContext>(defaultHttpClientContext)

type AuthType = ReturnType<typeof useAuth>

export type HttpClientOptions = {
  onError?: (errorMessage: string) => void
  logout: () => void
}

let lastResponseStatus: number | undefined

export function createHttpClient(
  auth: AuthType,
  clientOptions: HttpClientOptions,
): IHttpClientContext {
  return {
    isInitialized: auth.isAuthenticated,
    get lastResponseStatus() {
      return lastResponseStatus
    },
    get: <ResponseType>(path: string, options?: HttpRequestOptions) =>
      sendApiRequest<ResponseType>({
        verb: "GET",
        auth,
        path,
        ...options,
        clientOptions,
      }),

    post: <ResponseType>(path: string, body?: any, options?: HttpRequestOptions) =>
      sendApiRequest<ResponseType>({
        verb: "POST",
        auth,
        path,
        ...options,
        body,
        clientOptions,
      }),

    put: <ResponseType>(path: string, body?: any, options?: HttpRequestOptions) =>
      sendApiRequest<ResponseType>({
        verb: "PUT",
        auth,
        path,
        ...options,
        body,
        clientOptions,
      }),

    patch: <ResponseType>(path: string, body?: any, options?: HttpRequestOptions) =>
      sendApiRequest<ResponseType>({
        verb: "PATCH",
        auth,
        path,
        ...options,
        body,
        clientOptions,
      }),

    delete: (path: string, body?: any, options?: HttpRequestOptions) =>
      sendApiRequest<boolean>({
        verb: "DELETE",
        auth,
        path,
        ...options,
        body,
        clientOptions,
      }),
  }
}

type FullRequestOptions = {
  auth: AuthType
  verb: "GET" | "POST" | "PUT" | "PATCH" | "DELETE"
  path: string
  apiVersion?: number
  queryParams?: Record<string, string>
  errorHandlers?: ErrorHandlers
  body?: any
  clientOptions: HttpClientOptions
}

async function sendApiRequest<ResponseType>(
  options: FullRequestOptions,
): Promise<ResponseType | null> {
  const { auth, verb, path, queryParams, errorHandlers, body, clientOptions } = options
  if (!auth.isAuthenticated) {
    console.warn("sendApiRequest: user not authenticated")
    auth.login()
    return null
  }
  const accessToken = await auth.getAccessToken()
  if (!accessToken) {
    console.warn("sendApiRequest: missing access token")
    auth.login()
    return null
  }
  const version = options.apiVersion || 1
  const cleanPath = path.startsWith("/") ? path : `/${path}`
  const url = new URL(`${Environment.SERVER_URL}/api/v${version}${cleanPath}`)

  const handleForbidden = () => {
    if (!errorHandlers?.onForbidden?.()) {
      clientOptions.logout()
    }
  }

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value)
    })
  }
  try {
    console.debug(`Request: ${verb} ${url}`)
    const response = await fetch(url, {
      method: verb,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: body ? JSON.stringify(body) : undefined,
    })

    lastResponseStatus = response.status

    if (response.status == 204) {
      return true as ResponseType
    } else if (response.ok) {
      const text = await response.text()
      return JSON.parse(text, reviveWithDates) as ResponseType
    } else if (response.status === 401) {
      if (!errorHandlers?.onUnauthorized?.()) {
        clientOptions.logout()
      }
    } else if (response.status === 403) {
      try {
        const error = await response.json()
        const message = error?.message ?? error?.detail ?? ""
        // We get a specific error message from AWS WAF when the IP is outside the US
        // In that case, we have a custom error page. Otherwise we should just use the default 403 handler
        if (message.includes("your location is not allowed")) {
          if (!errorHandlers?.onGeoblocked?.()) {
            clientOptions.logout()
          }
        } else {
          handleForbidden()
        }
      } catch {
        handleForbidden()
      }
    } else {
      const defaultErrorHandler = async () => {
        const error = await response.json()
        const message = error?.message || error
        console.error(
          `sendApiRequest: http error: status: ${response.status} ${response.statusText}, message: ${JSON.stringify(message)}`,
        )

        if (clientOptions.onError) {
          clientOptions.onError("Something went wrong, please try again")
        }
      }
      if (response.status === 400) {
        if (!errorHandlers?.onBadRequest?.()) {
          defaultErrorHandler()
        }
      } else if (response.status === 404) {
        if (!errorHandlers?.onNotFound?.()) {
          defaultErrorHandler()
        }
      } else if (response.status >= 500) {
        if (!errorHandlers?.onServerError?.()) {
          defaultErrorHandler()
        }
      } else {
        defaultErrorHandler()
      }
    }
  } catch (error) {
    console.error(`Error in sendApiRequest: ${error}`)

    if (clientOptions.onError) {
      clientOptions.onError("Something went wrong, please try again")
    }
  }

  return null
}
