import { Routes } from "../../routing"
import { TabBar, TabProps } from "./components/TabBar"
import { useFeatureFlag } from "app/feature-flags/useFeatureFlag"

const tabs: TabProps[] = [
  { value: Routes.home, label: "Home", iconName: "house" },
  { value: Routes.explore, label: "Explore", iconName: "compass" },
  { value: Routes.chat, label: "Chat", iconName: "comment-lines" },
  { value: Routes.profile, label: "Profile", iconName: "user-circle" },
  { value: Routes.settings, label: "Settings", iconName: "cog" },
]

export function TabNavigator({ children }: { children: React.ReactNode }) {
  const modulesFlag = useFeatureFlag("release-modules", false)
  const homepageFlag = useFeatureFlag("modules-homepage", false)

  const filteredTabs = tabs.filter(
    (tabDetails) =>
      (modulesFlag || tabDetails.label !== "Explore") &&
      (homepageFlag || tabDetails.label !== "Home"),
  )

  return <TabBar tabs={filteredTabs}>{children}</TabBar>
}
