export const QueryParams = {
  threadId: "threadId",
  displayName: "displayName",
  categoryName: "categoryName",
  subcategoryId: "subcategoryId",
  subcategoryName: "subcategoryName",
  dataType: "dataType",
  units: "units",
  starterId: "starterId",
} as const

export const paramThreadIdNew = "new"
