import { namedPrimitivesFlat as primitives } from "../primitives"
import healthyColors from "../healthyColors"
import defaultColors from "../default/tokens"

export const light = {
  ...primitives,

  // Tamagui built-in theme values
  background: primitives.sand98,
  backgroundPrimary: primitives.grey95,
  backgroundHover: primitives.grey93,
  backgroundPress: primitives.grey93,
  backgroundFocus: "white",

  borderColor: primitives.grey90,
  borderColorHover: primitives.grey70,
  borderColorPress: primitives.grey60,
  borderColorFocus: primitives.sand93,

  color: primitives.grey10,
  colorInverse: primitives.grey98,
  colorHover: primitives.grey10,
  colorPress: primitives.grey10,
  colorFocus: primitives.grey10,

  shadowColor: primitives.grey60,
  shadowColorHover: primitives.grey60,
  shadowColorPress: primitives.grey60,
  shadowColorFocus: primitives.grey60,

  // ClosedLoop additional theme values
  backgroundSecondary: "white",
  backgroundDetail: "white",
  backgroundSelected: primitives.sky95,
  backgroundInfo: primitives.sky60,
  backgroundSuccess: primitives.fern60,
  backgroundSuccessLight: primitives.fern80,
  backgroundWarning: primitives.amber70,
  backgroundWarningLight: primitives.grey90,
  backgroundError: primitives.prawn50,
  backgroundInverse: primitives.grey10,
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundInput: primitives.sky98,
  backgroundMessage: primitives.sky98,

  borderContainer: primitives.grey95,
  borderError: primitives.prawn50,
  borderWarning: primitives.amber60,
  borderFocus: primitives.coal30,
  borderSelected: primitives.sky40,

  buttonPrimary: healthyColors.primary,
  buttonPrimaryHover: primitives.evergreen60,
  buttonPrimaryPress: primitives.evergreen40,
  buttonPrimaryFocus: primitives.evergreen60,
  buttonPrimaryDisabled: primitives.evergreen80,
  buttonPrimaryText: primitives.grey98,

  buttonSecondary: primitives.evergreen95,
  buttonSecondaryHover: primitives.evergreen98,
  buttonSecondaryPress: primitives.evergreen93,
  buttonSecondaryFocus: primitives.evergreen98,
  buttonSecondaryDisabled: primitives.grey30,

  buttonSecondaryText: primitives.sand10,
  buttonTertiary: "transparent",
  buttonTertiaryHover: primitives.sky93,
  buttonTertiaryPress: primitives.sky70,
  buttonTertiaryFocus: primitives.sky80,
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: primitives.sand10,
  buttonTertiaryBorder: defaultColors.light.blue5,

  icon: primitives.grey10,
  iconDark: primitives.grey98,
  iconLight: primitives.grey10,
  iconSecondary: primitives.grey40,
  iconError: primitives.prawn50,
  iconWarning: primitives.amber60,
  iconSuccess: primitives.fern98,
  iconInfo: primitives.sky40,
  iconSelected: primitives.sky50,
  iconAction: primitives.sky50,
  iconDangerous: primitives.prawn50,
  iconDisabled: primitives.grey80,
  iconBrand: healthyColors.primary,

  text: primitives.grey10,
  textInverse: primitives.grey10,
  textHover: primitives.grey10,
  textPress: primitives.grey10,
  textFocus: primitives.grey10,
  textSecondary: primitives.grey40,
  textDisabled: primitives.grey80,
  textDark: primitives.grey98,
  textLight: primitives.grey10,
  textLink: primitives.sky50,
  textAction: primitives.sky50,
  textSelected: primitives.grey10,
  textDangerous: primitives.prawn50,
  textWarning: primitives.amber60,

  chartAxis: primitives.grey90,
  chartGrid: primitives.grey60,
  chartGridDark: primitives.grey50,
  chartLabel: primitives.grey60,
  chartTicks: primitives.grey60,
  chartTicksLabels: primitives.grey60,
  chartDataBorder: primitives.grey90,
  chartDataPrimary: primitives.sky80,
  chartDataWarning: primitives.amber70,

  switchOff: primitives.grey60,
  switchOn: primitives.fern60,
  switchThumb: primitives.coal98,
}

export type BaseTheme = typeof light

export const dark: BaseTheme = {
  ...primitives,

  // Tamagui built-in theme values
  background: primitives.grey10,
  backgroundPrimary: primitives.grey30,
  backgroundHover: primitives.grey10,
  backgroundPress: primitives.grey10,
  backgroundFocus: "white",

  borderColor: primitives.grey30,
  borderColorHover: primitives.grey20,
  borderColorPress: primitives.grey20,
  borderColorFocus: primitives.grey20,

  color: primitives.grey98,
  colorInverse: primitives.grey10,
  colorHover: primitives.grey80,
  colorPress: primitives.grey80,
  colorFocus: primitives.grey80,

  shadowColor: primitives.grey10,
  shadowColorHover: primitives.grey10,
  shadowColorPress: primitives.grey10,
  shadowColorFocus: primitives.grey10,

  // ClosedLoop additional theme values
  backgroundSecondary: primitives.grey20,
  backgroundDetail: primitives.grey20,
  backgroundSelected: primitives.sky20,
  backgroundInfo: primitives.sky60,
  backgroundSuccess: primitives.fern60,
  backgroundSuccessLight: primitives.fern50,
  backgroundWarning: primitives.amber70,
  backgroundWarningLight: primitives.amber90,
  backgroundError: primitives.prawn50,
  backgroundInverse: primitives.grey10,
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundInput: primitives.grey30,
  backgroundMessage: primitives.grey10,

  borderContainer: primitives.grey30,
  borderError: primitives.prawn60,
  borderWarning: primitives.amber70,
  borderFocus: primitives.coal30,
  borderSelected: primitives.sky40,

  buttonPrimary: healthyColors.primary,
  buttonPrimaryHover: primitives.evergreen60,
  buttonPrimaryPress: primitives.evergreen40,
  buttonPrimaryFocus: primitives.evergreen60,
  buttonPrimaryDisabled: primitives.grey80,
  buttonPrimaryText: primitives.grey93,
  buttonSecondary: primitives.evergreen95,
  buttonSecondaryHover: primitives.evergreen98,
  buttonSecondaryPress: primitives.evergreen93,
  buttonSecondaryFocus: primitives.evergreen98,
  buttonSecondaryDisabled: primitives.grey30,
  buttonSecondaryText: primitives.sand10,
  buttonTertiary: "transparent",
  buttonTertiaryHover: primitives.sky93,
  buttonTertiaryPress: primitives.sky70,
  buttonTertiaryFocus: primitives.sky80,
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: primitives.grey93,
  buttonTertiaryBorder: defaultColors.light.blue5,

  icon: primitives.grey93,
  iconDark: primitives.grey10,
  iconLight: primitives.grey93,
  iconSecondary: primitives.grey60,
  iconError: primitives.prawn60,
  iconWarning: primitives.amber70,
  iconSuccess: primitives.fern98,
  iconInfo: primitives.sky60,
  iconSelected: primitives.sky60,
  iconAction: primitives.sky60,
  iconDangerous: primitives.prawn60,
  iconDisabled: primitives.grey40,
  iconBrand: healthyColors.primary,

  text: primitives.grey93,
  textInverse: primitives.grey93,
  textHover: primitives.grey80,
  textPress: primitives.grey80,
  textFocus: primitives.grey80,
  textSecondary: primitives.grey60,
  textDisabled: primitives.grey40,
  textDark: primitives.grey10,
  textLight: primitives.grey93,
  textLink: primitives.sky60,
  textAction: primitives.sky60,
  textSelected: primitives.grey98,
  textDangerous: primitives.prawn60,
  textWarning: primitives.amber60,

  chartAxis: primitives.grey20,
  chartGrid: primitives.grey50,
  chartGridDark: primitives.grey70,
  chartLabel: primitives.grey60,
  chartTicks: primitives.grey50,
  chartTicksLabels: primitives.grey60,
  chartDataBorder: primitives.grey20,
  chartDataPrimary: primitives.sky80,
  chartDataWarning: primitives.amber70,

  switchOff: primitives.grey40,
  switchOn: primitives.fern60,
  switchThumb: primitives.grey30,
}

export default {
  light,
  dark,
}
